
*:focus {
	outline: none;
}

button:focus {
	outline: none;
}

.header-top .top-message p a {
	border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.header-top .close {
	display: inline-block;
	width: 20px;
	height: 20px;
	vertical-align: top;
	position: absolute;
	top: 50%;
	right: -3px;
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	margin-top: -.5px;
}

.svg-mb {
	width: 60px;
	height: 50px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
}

.svg-mb a {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

.svg-mb .icon-search svg {
	position: relative;
	top: -1px;
}

.icon-user svg {
	width: 21px;
	height: 21px;
	stroke-width: 10px;
}

.icon-search {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

.icon-search + .search-form {
	display: none;
}

.cart-icon {
	position: relative;
	padding-right: 10px;
	width: 65px;
}

.cart-icon svg {
	stroke: rgba(255, 255, 255, 0);
	width: 22px;
	height: 23px;
}

.cart-icon .cartCount {
	position: absolute;
	top: 7px;
	right: -1px;
	min-width: 23px;
	min-height: 23px;
	text-align: center;
	padding: 2px 0px 0 1px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	z-index: 2;
	font-weight: 500;
}

.icon-nav {
	border: none;
	outline: none;
	padding: 0;
	display: block;
	width: 24px;
	height: 24px;
	position: relative;
}

.icon-nav .icon-line {
	display: block;
	width: 24px;
	height: 2px;
	margin: 3px 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	background-color: #1c4324;
}

.icon-nav .icon-line:before, .icon-nav .icon-line:after {
	background-color: #1c4324;
	display: block;
	width: 24px;
	height: 2px;
	margin: 3px 0;
	position: absolute;
	left: 0;
	right: 0;
	content: "";
	-webkit-transition: transform 0.3s;
	-moz-transition: transform 0.3s;
	-ms-transition: transform 0.3s;
	-o-transition: transform 0.3s;
	transition: transform 0.3s;
	transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	-webkit-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	-ms-transition-delay: 0.2s;
}

.icon-nav .icon-line:before {
	top: 5px;
}

.icon-nav .icon-line:after {
	top: 12px;
}

.close-menu-mb {
	display: none;
}

.close-menu-mb.menu-open {
	display: block;
	position: fixed;
	background: #ef3551;
	z-index: 33;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
}

.close-menu-mb.menu-open .icon-line {
	background-color: rgba(255, 255, 255, 0);
}

.close-menu-mb.menu-open .icon-line:before, .close-menu-mb.menu-open .icon-line:after {
	background-color: #ffffff;
	left: 8px;
}


.header-mb,
.header-mb-items {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
}

.header-logo {
	margin: 0;
	font-size: 20px;
}

.header-logo .logo-title {
	display: inline-block;
}

.header-logo .logo-title svg {
	width: 100%;
	height: auto;
}

.header-mb .header-mb-middle {
	width: -moz-calc(100% - (120px + 125px));
	width: -webkit-calc(100% - (120px + 125px));
	width: -ms-calc(100% - (120px + 125px));
	width: calc(100% - (120px + 125px));
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	padding-left: 5px;
}

.header-mb .header-mb-middle .header-logo {
	padding: 1px 5px 3px;
}

.header-mb .header-mb-right {
	-webkit-box-pack: flex-end;
	-moz-box-pack: flex-end;
	-ms-flex-pack: flex-end;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
	width: 125px;
}

.header-mb .header-mb-right .svg-mb {
	-webkit-box-pack: flex-end;
	-moz-box-pack: flex-end;
	-ms-flex-pack: flex-end;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}

.wishlist .fa {
	font-size: 14px;
	margin-right: 5px;
	line-height: 20px;
	vertical-align: middle;
}

.wishlist .wishlist-text {
	display: inline-block;
	vertical-align: middle;
}

.nav-search .input-group-field {
	border: none;
	background-color: rgba(255, 255, 255, 0);
	margin: 0;
	font-size: 12px;
	line-height: 20px;
}

.nav-search .icon-search {
	background: rgba(255, 255, 255, 0);
	min-width: inherit;
}

.lang-currency-groups .label-text {
	display: block;
	font-size: 13px;
	letter-spacing: normal;
	text-transform: capitalize;
	font-weight: normal;
	padding: 7px 0 6px;
}

.lang-currency-groups .dropdown-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: static;
	border: none;
	padding: 0;
	width: 100%;
	min-width: inherit;
	margin: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
	z-index: inherit;
	top: inherit;
	left: inherit;
	float: none;
	font-size: 12px;
	background: inherit;
}

.lang-currency-groups .btn-group {
	display: block;
	text-transform: uppercase;
}

.lang-currency-groups .btn-group .dropdown-item {
	display: block;
	padding: 9px 15px 7px 0;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
	color: #3c3c3c;
}

.lang-currency-groups .btn-group .dropdown-item .img-icon,
    .lang-currency-groups .btn-group .dropdown-item .text {
	display: inline-block;
	vertical-align: middle;
}

.lang-currency-groups .btn-group .dropdown-item .text {
	position: relative;
	top: 2.5px;
	border-bottom: 1px solid rgba(255, 255, 255, 0);
	line-height: 14px;
}

.lang-currency-groups .btn-group .dropdown-item.active .text {
	font-weight: 500;
	border-bottom-color: #3c3c3c;
}

.lang-currency-groups .btn-group .dropdown-item:hover, .lang-currency-groups .btn-group .dropdown-item:focus, .lang-currency-groups .btn-group .dropdown-item:active, .lang-currency-groups .btn-group .dropdown-item.active {
	color: inherit;
	background-color: inherit;
}

.lang-currency-groups .btn-group .img-icon {
	margin-right: 8px;
	width: 23px;
	display: block;
}

.lang-currency-groups .btn-group .img-icon img {
	min-height: 16px;
	width: auto;
	object-fit: contain;
}

.customer-content {
	text-align: left;
}

.customer-content .action-btn {
	margin-bottom: 22px;
	text-align: center;
}

.customer-content .action-btn .forgot-password {
	color: #3a575d;
}

.customer-content .action-btn .forgot-password:before {
	background-color: #3a575d !important;
}

.customer-content .btn, .customer-content .product-shop .shopify-payment-button button.shopify-payment-button__button, .product-shop .shopify-payment-button .customer-content button.shopify-payment-button__button, .customer-content .product-shop .groups-btn .wishlist, .product-shop .groups-btn .customer-content .wishlist, .customer-content .tabs__product-page .spr-container .spr-summary-actions-newreview, .tabs__product-page .spr-container .customer-content .spr-summary-actions-newreview, .customer-content .addresses-page .btn-edit-delete a, .addresses-page .btn-edit-delete .customer-content a {
	display: block;
	width: 100%;
}


.customer-content .form-group {
	margin-bottom: 15px;
}

.customer-content .form-group label {
	font-weight: 500;
}

.customer-content .form-group .form-control {
	border-color: #cbcbcb;
}

.customer-content .form-group input {
	-webkit-box-shadow: 0 0 0 40px white inset;
	-webkit-text-fill-color: #3a575d;
}

.customer-content .form-group input:focus {
	box-shadow: none;
	-webkit-box-shadow: 0 0 0 40px white inset;
	-webkit-text-fill-color: #3a575d;
}

.customer-content .acc {
	color: #3a575d;
	display: block;
	margin-bottom: 16px;
	text-align: center;
	font-weight: 500;
}

.customer-content .btn-logout {
	background: #3a575d;
	color: white;
	border-color: #3a575d;
}

.customer-content .btn-logout:hover {
	background: white;
	color: #3a575d;
	border-color: #3a575d;
}

.ct_login {
	background-color: white;
	padding: 15px 20px 25px;
}

/* ----- Navigation ------- */
.site-nav li {
	margin: 0;
}

.site-nav li a {
	position: relative;
	display: block;
}

.site-nav li a .icon-dropdown {
	opacity: 0.4;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=40)";
	filter: alpha(opacity=40);
	right: 6px;
}

.site-nav .icon-dropdown {
	width: 20px;
	display: inline-block;
	font-size: 19px;
	position: absolute;
	top: 13px;
}

.site-nav .menu-mb-title {
	letter-spacing: .05em;
	font-size: 13px;
	font-weight: 600;
	text-transform: uppercase;
	padding: 13px 20px 11px;
	position: relative;
	text-align: center;
	cursor: pointer;
}

.site-nav .menu-mb-title .icon-dropdown {
	left: 20px;
	text-align: left;
}

.site-nav .menu-lv-1 > a {
	letter-spacing: .05em;
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
	padding: 13px 20px 11px;
}

.site-nav .menu-lv-2 > a {
	padding: 13px 20px 11px;
}

.site-nav .menu-lv-2 .site-nav-dropdown {
	margin: 13px 0;
}

.site-nav .menu-lv-3 > a {
	padding: 8px 20px 6px;
}

.site-nav .mega-menu .menu-lv-2 > a {
	text-transform: uppercase;
	font-weight: 600;
}

.site-nav .mega-menu .mega-banner {
	padding: 8px 10px;
	text-align: center;
}

.site-nav .mega-menu .mega-banner .title {
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: .02em;
	line-height: 1.7em;
	color: #232323;
	margin-bottom: 8px;
	padding: 6px 0 4px;
}

.site-nav .mega-menu .mega-banner .col-left {
	margin-bottom: 18px;
}

.site-nav .mega-menu .mega-banner .col-right {
	padding-top: 12px;
}

.site-nav .mega-menu .product-item .product-bottom {
	text-align: center;
}

.site-nav .mega-menu .product-item .product-top {
	max-width: 120px;
	margin-left: auto;
	margin-right: auto;
}



.site-nav .mega-menu .product-item .product-image img {
	max-height: 160px;
}

.site-nav .mega-menu .product-item .spr-badge,
.site-nav .mega-menu .product-item .price-box {
	margin-bottom: 10px;
}

.site-nav .mega-menu .product-item .product-title {
	margin-bottom: 5px;
}

.site-nav .mega-menu .product-item .btn, .site-nav .mega-menu .product-item .product-shop .shopify-payment-button button.shopify-payment-button__button, .product-shop .shopify-payment-button .site-nav .mega-menu .product-item button.shopify-payment-button__button, .site-nav .mega-menu .product-item .product-shop .groups-btn .wishlist, .product-shop .groups-btn .site-nav .mega-menu .product-item .wishlist, .site-nav .mega-menu .product-item .tabs__product-page .spr-container .spr-summary-actions-newreview, .tabs__product-page .spr-container .site-nav .mega-menu .product-item .spr-summary-actions-newreview, .site-nav .mega-menu .product-item .addresses-page .btn-edit-delete a, .addresses-page .btn-edit-delete .site-nav .mega-menu .product-item a {
	width: inherit;
	display: inline-block;
	min-width: inherit;
	padding: 0;
	border: none;
	font-size: 12px;
	border-bottom: 1px solid rgba(35, 35, 35, 0.7);
	background: rgba(255, 255, 255, 0);
}

.site-nav .mega-menu .style_2 .mega-banner {
	padding-top: 18px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}

.site-nav .mega-menu .style_2 .mega-banner .col-right {
	margin-left: 6px;
}

.site-nav .mega-menu .style_2 .mega-banner .col-left > a {
	margin-bottom: 6px;
}

.site-nav .mega-menu .style_2 .mega-col {
	padding: 0;
	margin: 0;
}

.site-nav .mega-menu .style_3 .menu-lv-2 img {
	margin: 5px 0 5px;
}

.site-nav .mega-menu .style_5 .two-mega-banner {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 10px -10px 12px;
}

.site-nav .mega-menu .style_5 .two-mega-banner .mega-col {
	padding: 0 10px;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.site-nav .mega-menu .mega-cms-block {
	margin-top: 5px;
}

.site-nav .mega-menu .mega-cms-block .title {
	font-size: 12px;
	line-height: 20px;
	text-transform: uppercase;
	letter-spacing: normal;
	font-weight: 600;
	margin-bottom: 8px;
}

.site-nav .mega-menu .mega-cms-block .rte-setting {
	line-height: 22px;
	font-style: italic;
	font-size: 12px;
	color: #999999;
	margin-bottom: 17px;
}

.site-nav .mega-menu .mega-cms-block .read-more {
	display: inline-block;
	line-height: 22px;
	color: #999999;
	font-weight: 500;
}

.site-nav .mega-menu .mega-cms-block .read-more:before {
	width: 100%;
	background-color: #999999;
	visibility: visible;
	bottom: 1px;
}

.site-nav .icon-label {
	font-size: 10px;
	font-weight: 500;
	letter-spacing: normal;
	margin: 0 0 0 10px;
	line-height: 1em;
	padding: 4px 6px 3px;
	text-transform: capitalize;
	position: relative;
	top: -1px;
	-moz-border-radius: 1px;
	-webkit-border-radius: 1px;
	-ms-border-radius: 1px;
	-o-border-radius: 1px;
	border-radius: 1px;
	text-align: center;
}

.site-nav .icon-label:before {
	content: "";
	border: 5px solid transparent;
	position: absolute;
}



.home-slideshow .fluid-width-video-wrapper {
	width: 100%;
	position: relative;
	padding: 0;
}

.home-slideshow [data-video-mp4] .video {
	position: absolute;
	top: -5px;
	left: 0;
	width: 100%;
}

.home-slideshow [data-video-mp4] .fluid-width-video-wrapper:before {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: "";
	z-index: 2;
	opacity: 0.4;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=40)";
	filter: alpha(opacity=40);
	background-color: #866965;
	position: absolute;
}



.slide-content {
	text-align: center;
	padding: 25px 25px 30px;
}

.slide-content:after {
	display: table;
	content: "";
}

.slide-content .slide-sub {
	letter-spacing: .02em;
	text-transform: capitalize;
	margin-bottom: 20px;
	font-weight: 400;
}

.slide-content .slide-heading {
	letter-spacing: .05em;
	font-weight: 700;
	margin-bottom: 24px;
	text-transform: uppercase;
	position: relative;
	padding-bottom: 17px;
}

.slide-content .slide-heading .line {
	position: absolute;
	left: 50%;
	bottom: 0;
	-moz-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	height: 2px;
	width: 40px;
}

.slide-content .slide-text {
	margin-bottom: 35px;
	max-width: 425px;
	margin-left: auto;
	margin-right: auto;
}

.slide-content .slide-button {
	min-width: 270px;
	padding: 10px 25px 8px;
	-webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.slide-content .slide-button:hover {
	background-color: white !important;
	color: #3a575d !important;
	border-color: white !important;
}

/* ----- Sub Banner ------- */
.border-bt-hover {
	position: absolute;
	left: 0;
	width: 0;
	bottom: -.3rem;
	height: 2px;
	content: '';
	display: block;
	-webkit-transition: width 0.3s ease;
	-moz-transition: width 0.3s ease;
	-ms-transition: width 0.3s ease;
	-o-transition: width 0.3s ease;
	transition: width 0.3s ease;
}

.banner-item {
	position: relative;
}

.banner-item .img-box {
	text-align: center;
}

.banner-item .banner-title {
	font-size: 16px;
	letter-spacing: .05em;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
	display: inline-block;
	position: relative;
}

.banner-item .des {
	font-size: 12px;
	line-height: 22px;
}

.banner-item .content-box {
	position: absolute;
	top: 50%;
	left: 50%;
	-moz-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	min-width: 90%;
	text-align: center;
}

.banner-item:hover .border-bt-hover {
	width: 100%;
}

/* ----- Product Grid ------- */
.widget-title {
	text-align: center;
	padding-top: 15px;
	margin-bottom: 25px;
}

.widget-title .box-title {
	color: #3a575d;
	font-size: 20px;
	letter-spacing: .05em;
	font-weight: 700;
	text-transform: uppercase;
	position: relative;
	margin-bottom: 15px;
}

.widget-title .box-title .title {
	padding: 0 24px;
	position: relative;
	z-index: 1;
	background-color: white;
}

.widget-title .box-title:before {
	position: absolute;
	content: "";
	height: 1px;
	background-color: #e0e0e0;
	top: -moz-calc(50% - 0.5px);
	top: -webkit-calc(50% - 0.5px);
	top: -ms-calc(50% - 0.5px);
	top: calc(50% - 0.5px);
	left: 0;
	right: 0;
	width: 100%;
}

.widget-title .box-title + p {
	margin-bottom: 30px;
	line-height: 22px;
	max-width: 770px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	top: -2px;
}

.widget-title .view_all {
	display: inline-block;
	text-transform: uppercase;
	font-weight: 600;
	color: #3a575d;
	padding-left: 13px;
	position: relative;
}

.widget-title .view_all:before {
	position: absolute;
	content: "";
	top: 3px;
	left: 5px;
	width: 1px;
	height: 14px;
	background-color: #787878;
}

.widget-title.not-before {
	margin-top: 0;
}

.widget-title.not-before .box-title .title {
	background-color: rgba(255, 255, 255, 0);
}

.widget-title.not-before .box-title:before {
	display: none;
}

.widget-title.style-3 {
	text-align: left;
	padding: 0;
	position: relative;
}

.widget-title.style-3 .box-title {
	border-bottom: 2px solid #e0e0e0;
	padding: 12px 60px 12px  0;
}

.widget-title.style-3 .box-title .title {
	padding: 0;
}

.widget-title.style-3 .view_all {
	position: absolute;
	top: 14px;
	right: 0;
	margin-top: 0;
	text-transform: capitalize;
	font-weight: normal;
	color: #3a575d;
}

.widget-title.style-3 .view_all:before {
	display: none;
}

.image-swap {
	position: relative;
}

.image-swap .images-one,
  .image-swap .images-two {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.image-swap .images-two {
	position: absolute !important;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: none;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(display=none)";
	filter: alpha(display=none);
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.sizes-list {
	margin: 0 0 15px;
	letter-spacing: -.33em;
}

.sizes-list li {
	display: inline-block;
	letter-spacing: normal;
	margin: 0 2.5px 0;
}

.sizes-list li:first-of-type {
	margin-left: 0;
}

.sizes-list li:last-of-type {
	margin-right: 0;
}

.sizes-list li a {
	min-width: 27px;
	-moz-border-radius: 13px;
	-webkit-border-radius: 13px;
	-ms-border-radius: 13px;
	-o-border-radius: 13px;
	border-radius: 13px;
	color: #2d2d2d;
	border: 1px solid #e7e7e7;
	background-color: #fff;
	display: block;
	text-align: center;
	padding: 3px 5px 2px;
	font-weight: 500;
}

.sizes-list li a:hover {
	background: #3a575d;
	color: white;
	border-color: #3a575d;
}

.sizes-list li.hide-col5 a {
	border: none;
	background-color: transparent;
}

.sizes-list li.hide-col5 a:hover {
	color: #2d2d2d;
	background-color: transparent;
}

.item-swatch {
	list-style: none;
	padding: 0;
	margin: 0;
	letter-spacing: -.33em;
	line-height: 0;
}

.item-swatch li {
	display: inline-block;
	margin: 0 4px 0;
	letter-spacing: normal;
	text-align: center;
	vertical-align: middle;
	position: relative;
}

.item-swatch li:first-of-type {
	margin-left: 0;
}

.item-swatch li:last-of-type {
	margin-right: 0;
}

.item-swatch li label {
	width: 30px;
	height: 30px;
	-moz-border-radius: 15px;
	-webkit-border-radius: 15px;
	-ms-border-radius: 15px;
	-o-border-radius: 15px;
	border-radius: 15px;
	margin: 0 0 0;
	background-size: cover;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	border: 3px solid #fff;
	-webkit-box-shadow: 0 0 0 1px #e6e6e6;
	-moz-box-shadow: 0 0 0 1px #e6e6e6;
	box-shadow: 0 0 0 1px #e6e6e6;
	overflow: hidden;
}

.item-swatch li label.active {
	-webkit-box-shadow: 0 0 1px 2px #e95144;
	-moz-box-shadow: 0 0 1px 2px #e95144;
	box-shadow: 0 0 1px 2px #e95144;
}

.item-swatch li label img {
	max-height: 24px;
	max-width: 24px;
}

.item-swatch li label img.file_img {
	width: 100%;
	hieght: 100%;
}

.item-swatch li a {
	width: 30px;
	height: 30px;
	display: inline-block;
	cursor: pointer;
	line-height: 31px;
	text-align: center;
	font-size: 12px;
	font-weight: 500;
	color: #2d2d2d;
}

.item-swatch li .tooltip {
	text-align: center;
	background: #3a575d;
	color: white;
	bottom: 100%;
	padding: 4px 7px;
	display: block;
	position: absolute;
	z-index: 2;
	min-width: 44px;
	left: 50%;
	-moz-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	margin-bottom: 12px;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=0)";
	filter: alpha(opacity=0);
	visibility: hidden;
	-webkit-transition: all 0.25s ease-out;
	-moz-transition: all 0.25s ease-out;
	-ms-transition: all 0.25s ease-out;
	-o-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;
	-webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
	-moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
	box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
	font-size: 11px;
	font-weight: 500;
	letter-spacing: normal;
	pointer-events: none;
}

.item-swatch li .tooltip:after {
	border-left: solid transparent 7px;
	border-right: solid transparent 7px;
	border-top: solid #3a575d 7px;
	bottom: -7px;
	content: " ";
	height: 0;
	left: 50%;
	-moz-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	position: absolute;
	width: 0;
}

.item-swatch li:hover .tooltip {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";
	filter: alpha(opacity=100);
	visibility: visible;
	pointer-events: auto;
}

.product-label {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	letter-spacing: -.33em;
	text-align: right;
	pointer-events: none;
}

.product-label .label {
	line-height: 1em;
	font-size: 12px;
	font-weight: 600;
	text-transform: capitalize;
	padding:  7px ;
	border: none;
	margin: 0;
	-moz-border-radius: 1px;
	-webkit-border-radius: 1px;
	-ms-border-radius: 1px;
	-o-border-radius: 1px;
	border-radius: 3px;
	min-width: 40px;
	min-height: 19px;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
	letter-spacing: 1.01px;
	text-align: center;
}

.product-label .sale-label {
	background-color: #ef3551;
	color: white;
	margin-bottom: 5px;
}






.product-labels {
	position: relative;
margin-left: 25px;
	z-index: 2;
	letter-spacing: -.33em;
	text-align: right;
	pointer-events: none;
}

.product-labels .label {
	line-height: 1em;
	font-size: 12px;
	font-weight: 600;
	text-transform: capitalize;
	padding:  7px ;
	border: none;
	margin: 0;
	-moz-border-radius: 1px;
	-webkit-border-radius: 1px;
	-ms-border-radius: 1px;
	-o-border-radius: 1px;
	border-radius: 3px;
	min-width: 40px;
	min-height: 19px;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
	letter-spacing: 1.01px;
	text-align: center;
}

.product-labels .sale-label {
	background-color: #ef3551;
	color: white;
	margin-bottom: 5px;
}




















.quickview-button {
	background-color: rgba(58, 87, 93, 0.8);
	color: rgba(255, 255, 255, 0.7);
	font-size: 11px;
	font-weight: 600;
	line-height: 19px;
	text-transform: uppercase;
	padding: 1px 20px 0;
	width: 100%;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.quickview-button:hover {
	color: rgba(255, 255, 255, 0.7);
}

.product-vendor {
	margin-bottom: 4px;
}

.product-vendor span {
	color: #3a575d;
	font-size: 12px;
	font-weight: 500;
}

.product-vendor span:hover {
	color: #3a575d;
}

.product-title,
.product-name {
	color: #3a575d;
	display: block;
	margin-bottom: 4px;
	text-transform: capitalize;
	font-size: 12px;
	line-height: 22px;
	font-weight: 500;
}

.product-title:hover,
  .product-name:hover {
	color: #3a575d;
}

.price-box {
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: normal;
	margin-bottom: 12px;
}

.price-box .price-regular {
	color: #ef3551;
}

.price-box .old-price {
	color: #969696;
	text-decoration: line-through;
	margin-right: 5px;
}

.price-box .special-price {
	color: #ef3551;
}

.price-box em {
	font-size: 12px;
	color: #969696;
	font-weight: normal;
	font-style: normal;
	margin-right: 2px;
}

.spr-icon-star-empty:before,
.spr-icon.spr-icon-star:before,
.spr-icon:before {
	color: #3a575d;
}

.spr-badge-starrating {
	margin-right: 0 !important;
}

.spr-starrating.spr-summary-starrating {
	margin-right: 10px;
}

.spr-icon {
	padding: 0 2px;
	font-size: 105% !important;
	margin: 0;
}

.spr-icon:first-of-type {
	padding-left: 0;
}

.spr-icon.spr-icon-star, .spr-icon.spr-icon-star-hover {
	content: '\e800' !important;
}

.spr-icon.spr-icon-star-empty, .spr-icon.spr-icon-star-half-alt {
	opacity: 1 !important;
}

[class^="spr-icon-"]:before,
[class*=" spr-icon-"]:before {
	font-size: 100% !important;
}

.grid-item.grid-item-border .product-image {
	border: 1px solid #e6e6e6;
	padding: 10px;
}

.product-img-box.grid-item-border {
	border: 1px solid #e6e6e6;
	padding: 10px;
}

.products-grid .wishlist-text {
	display: none;
}

.products-grid .grid-item {
	margin-bottom: 50px;
	margin-top: 10px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.products-grid .grid-item .not_img {
	height: 270px;
	line-height: 270px;
	font-size: 18px;
}

.products-grid .grid-item .no-product .product-top {
	margin-bottom: 10px;
}

.products-grid .grid-item .product-details {
	display: none !important;
}

.products-grid .grid-item.banner-img img {
	width: 100%;
	object-fit: cover;
}

// .products-grid .product-image .product-grid-image {
// 	min-height: 434px;
// }

// .products-grid .product-image img {
//     min-height: 434px;
// }

.products-grid.grid-2, .products-grid.grid-3, .products-grid.grid-4, .products-grid.grid-5 {
	display: block;
	-webkit-column-gap: 0;
	-moz-column-gap: 0;
	column-gap: 0;
	padding-bottom: 40px;
}

.products-grid.grid-2 .grid-item, .products-grid.grid-3 .grid-item, .products-grid.grid-4 .grid-item, .products-grid.grid-5 .grid-item {
	break-inside: avoid;
	break-inside: avoid-column;
	page-break-inside: avoid;
	-webkit-column-break-inside: avoid;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

.products-grid.grid-2 {
	-moz-column-count: 2;
	-webkit-column-count: 2;
	column-count: 2;
}

.product-item .product-top {
	position: relative;
	text-align: center;
}

.product-item .product-grid-image {
	position: relative;
	display: block;
}



.product-item .product-image img {
	object-fit: contain;
	max-height: 100%;
	height:100%;
	width: 100%;
}

.product-item .wishlist {
	margin-bottom: 15px;
	display: inline-block;
	color: white;
}

.product-item .wishlist .fa {
	width: 26px;
	height: 26px;
	background-color: #3a575d;
	color: white;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	margin: 0;
	font-size: 13px;
	line-height: 13px;
	text-align: center;
	padding: 7px 6px 6px;
}

.product-item .wishlist.whislist-added .fa {
	color: #ef3551;
}

.product-item .spr-badge {
	margin-bottom: 9px;
}

.product-item .spr-badge .spr-badge-starrating + .spr-badge-caption {
	display: none;
}

.product-item .action {
	margin-bottom: 12px;
}



.product-item .product-bottom {
	text-align: center;
}

.product-item .product-des {
	text-align: center;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 2;
	display: none;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(display=none)";
	filter: alpha(display=none);
	-webkit-transition: display 0.5s ease;
	-moz-transition: display 0.5s ease;
	-ms-transition: display 0.5s ease;
	-o-transition: display 0.5s ease;
	transition: display 0.5s ease;
	visibility: hidden;
}

.product-item .product-des.abs-center {
	top: 50%;
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.product-item .product-des.abs-bottom {
	bottom: 0;
}



@media (min-width: 360px) and (max-width: 767px) {
	.item-swatch li label,
  .item-swatch li a {
		width: 23px;
		height: 23px;
		line-height: 23px;
	}

	.item-swatch li label {
		line-height: 1;
	}

	.item-swatch li label img {
		max-height: 16px;
		line-height: 16px;
	}

	.item-swatch li a {
		padding-top: 1px;
	}

	

	.suppermarket-product-item .product-title {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: normal;
		max-height: initial;
		overflow: hidden;
		text-overflow: ellipsis;
	}



	.me{
		display: block;
	}
}

@media (min-width: 768px) {
	.me{
		display: none;
	}
}
@media (min-width: 480px) and (max-width: 767px) {
	.home-sub-banner.sub-banner-3 .row {
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		overflow-x: auto;
		margin-left: 0;
		margin-right: 0;
	}

	.home-sub-banner.sub-banner-3 .three-blocks {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 42%;
		flex: 0 0 42%;
		max-width: 42%;
		padding-left: 0;
		padding-right: 0;
		margin-right: 20px;
	}

	.spotlight-3-blocks,
  .spotlight-2-blocks {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
	}

	.spotlight-3-blocks > .col-md-6,
    .spotlight-2-blocks > .col-md-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 370px;
		flex: 0 0 370px;
		max-width: 370px;
	}

	.spotlight-3-blocks > .col-md-6:last-of-type,
      .spotlight-2-blocks > .col-md-6:last-of-type {
		padding-right: 0 !important;
	}

	

	.home-collections .collections-slider {
		padding-left: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.home-collections .collections-slider .item {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 38%;
		flex: 0 0 38%;
		max-width: 38%;
	}

	.home-collections .collections-slider .item:last-of-type {
		padding-right: 0 !important;
	}

	.home-custom-block-images .row,
  .home-blog-posts:not(.blog-slider) .row {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
	}

	.home-custom-block-images .row .two-blocks,
    .home-custom-block-images .row .three-blocks,
    .home-blog-posts:not(.blog-slider) .row .two-blocks,
    .home-blog-posts:not(.blog-slider) .row .three-blocks {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 70%;
		flex: 0 0 70%;
		max-width: 70%;
	}

	.products-list .product-item .product-top {
		width: 160px;
		margin-right: 20px;
	}

	.products-list .product-item .product-details {
		width: -moz-calc(100% - 180px);
		width: -webkit-calc(100% - 180px);
		width: -ms-calc(100% - 180px);
		width: calc(100% - 180px);
	}

	.cart-template .wrapper-cart-template .cart-list .group-action {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.cart-template .wrapper-cart-template .cart-list .group-action .update {
		margin-bottom: 0;
		margin-right: 12px;
	}

	.cart-template .wrapper-cart-template .cart-list .group-action .remove {
		padding-left: 15px;
		padding-right: 15px;
		-webkit-box-pack: center;
		-moz-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		margin-left: 12px;
	}

	.home-blog-style2 .list-blogs .two-blocks,
  .home-blog-style2 .list-blogs .three-blocks {
		margin-right: 0 !important;
	}

	.home-blog-style2 .list-blogs .two-blocks:last-of-type .article-img,
    .home-blog-style2 .list-blogs .three-blocks:last-of-type .article-img {
		width: 100%;
	}
}

@media (min-width: 768px) {


	.product-template-slider .product-shop .product-title {
		text-align: center;
		margin-bottom: 13px;
	}



	



	.product-template-full-width .product-photos {
		margin-top: 7px;
	}



	

	.product-item .product-top:hover .images-one {
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(display=none)";
		filter: alpha(display=none);
	}

	.product-item .product-top:hover .images-two {
		display: block !important;
		background-color: #fff;
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(display=block)";
		filter: alpha(display=block);
	}

	.product-item .product-top:hover .product-des {
		display: block;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(display=block)";
		filter: alpha(display=block);
		visibility: visible;
	}

	.product-item .action {
		max-width: 170px;
		margin-left: auto;
		margin-right: auto;
	}
}