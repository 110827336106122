
.product-images-slider {
    .swiper-slide {
        // margin-top: 100%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    .swiper-button-prev {
        left: 0;
        color: #000;
    }

    .swiper-button-next {
        right: 0;
        color: #000;
    }
}



.respBig{
    width:400px !important;
}

@media only screen and (max-width: 767px) {
    .respBig{
        // width:350px;
        width:283px !important;
        height: 100%;
        margin-left:auto;
        margin-right:auto;
    }
    .secondResp{
        // width:"100%";
        width: 94vw;
        padding: 5px;
        -webkit-filter: drop-shadow(5px 5px 5px #222);
        filter: drop-shadow(5px 5px 5px #222);
    }
    
    .product-images-slider {
    .swiper-slide {
        // margin-top: 100%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    
}







}

.setHeight{
    height: 38em !important;
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
    .setHeight{
        height: 26em !important;
        margin-bottom: -156px !important;

    }
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
    .setHeight{
        height: 21em !important;

    }
    .respBig{
        width:215px !important;
    }
}
@media only screen and (min-width: 901px) and (max-width: 1030px) {
    .setHeight{
        height: 25em !important;

    }
    .respBig{
        width:260px !important;
    }
}
@media only screen and (min-width: 1031px) and (max-width: 1160px) {
    .setHeight{
        height: 29em !important;

    }
    .respBig{
        width:330px !important;
    }
}
@media only screen and (min-width: 1061px) and (max-width: 1280px) {
    .setHeight{
        height: 33em !important;

    }
    .respBig{
        width:366px !important;
    }
}
@media only screen and (min-width: 1281px) and (max-width: 1335px) {
    .setHeight{
        height: 34em !important;

    }
}
@media only screen and (max-width: 500px) {
    .setHeight{
        // height: 26em !important;
        // margin-bottom: -156px !important;
    }

}
